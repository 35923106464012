import { Alert, Box, Button, Container, Link, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { auth, signInWithEmailAndPassword, sendPasswordResetEmail } from '../firebase';
import { startSession } from '../session';
import { handleFirebaseAuthError } from '../functions/firebaseAuthErrorHandler';
import GoogleAuthButton from '../components/GoogleAuthButton';
import VKOneTabAuth from '../components/VKOneTabAuth';

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showResetPasswordMessage, setShowResetPasswordMessage] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();

    // validate the inputs
    if (!email || !password) {
      setError("Пожалуйста, введите email и пароль!");
      return;
    }

    // clear the errors
    setError("");

    try {
      let loginResponse = await signInWithEmailAndPassword(auth, email, password);
      if (!loginResponse.user.emailVerified) {
        setError("Пожалуйста, подтвердите ваш email-адрес.");
        return;
      }
      startSession(loginResponse.user);
      navigate("/user");
    } catch (error) {
      console.error(error.message);
      setError(handleFirebaseAuthError(error));
    }
  };

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setShowResetPasswordMessage(true);
    } catch (error) {
      console.error(error.message);
      setError(handleFirebaseAuthError(error));
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 2 }}>
      <Typography variant="h5" component="h1" gutterBottom textAlign="center">
        Чарник
      </Typography>
      {error && <Alert severity="error" sx={{ my: 2 }}>{error}</Alert>}
      {showResetPasswordMessage && (
        <Alert severity="success" sx={{ my: 2 }}>
          На ваш email-адрес отправлено письмо для сброса пароля.
        </Alert>
      )}
      <Box component="form" onSubmit={onSubmit}>
        <TextField
          label="Email"
          variant="outlined"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mt: 1 }}
          fullWidth
        />
        <TextField
          label="Пароль"
          variant="outlined"
          type="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mt: 3 }}
          fullWidth
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: '48%',
              backgroundColor: '#3f51b5',
              '&:hover': { backgroundColor: '#303f9f' },
            }}
          >
            Войти
          </Button>
          <Button
            onClick={handleResetPassword}
            variant="contained"
            sx={{
              width: '48%',
              backgroundColor: '#3f51b5',
              '&:hover': { backgroundColor: '#303f9f' },
            }}
          >
            Забыли пароль?
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <GoogleAuthButton />
          
          <VKOneTabAuth />
          <Link
            href="/register"
            sx={{
              width: '48%',
              ml: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#3f51b5',
              color: '#fff',
              textDecoration: 'none',
              padding: '10px 0',
              '&:hover': { backgroundColor: '#303f9f' },
            }}
          >
            Зарегестрироваться
          </Link>
        </Box>
      </Box>
    </Container>
  );
}