import { Button, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { endSession, getSession, isLoggedIn } from "../session";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import FirestoreUserList from '../components/FirestoreUserList';
import SaveUserData from '../components/SaveUserData';

export default function User() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setEmail(user.email);
        setIsEmailVerified(user.emailVerified);
        console.log("Ваш токен доступа: " + getSession().accessToken);
      } else {
        navigate("/login");
      }
    });

    return unsubscribe;
  }, [navigate]);

  const onLogout = () => {
    endSession();
    navigate("/login");
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 2 }}>
      {!isEmailVerified && (
        <Typography variant="h6" component="h1" textAlign="center" gutterBottom color="error">
          Пожалуйста, подтвердите ваш email-адрес, чтобы продолжить.
        </Typography>
      )}
      <Typography variant="h6" component="h1" textAlign="center" gutterBottom>
        Вы вошли в систему под логином:
      </Typography>
      <Typography variant="h5" component="h1" textAlign="center" gutterBottom>
        {email}
      </Typography>
      <Button variant="contained" color="error" onClick={onLogout} sx={{ mt: 3 }} fullWidth>
        Выйти
      </Button>
      {/*<FirestoreUserList />*/} {/* Добавленная строка */}
    </Container>
  );
}
