import { getFirestore, doc, setDoc } from "firebase/firestore";

export async function SaveUserData(user, displayName) {
  const db = getFirestore();
  const { uid, email, password } = user;

  try {
    await setDoc(doc(db, "users", uid), {
      email,
      displayName,
      uid,
      password,
      // Добавьте другие данные пользователя, которые хотите сохранить
    });
    console.log("Данные пользователя (email:", email, ", displayName:", displayName, ", password:", password, ", uid:", uid, ") сохранены в Firestore");
  } catch (error) {
    console.error("Ошибка при сохранении данных пользователя:", error);
    throw error;
  }
}
