import { Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider, getAuth } from 'firebase/auth';
import { startSession } from '../session';
import { handleFirebaseAuthError } from '../functions/firebaseAuthErrorHandler';
import { useState } from 'react';
import { FcGoogle } from 'react-icons/fc';

const GoogleAuthButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const { user } = await signInWithPopup(auth, provider);
      startSession(user);
      navigate('/user');
    } catch (error) {
      console.error('Ошибка авторизации через Google:', error);
      handleFirebaseAuthError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IconButton
      color="primary"
      onClick={handleGoogleSignIn}
      disabled={isLoading}
      sx={{
        backgroundColor: '#005fcc',
        '&:hover': { backgroundColor: '#303f9f' },
        borderRadius: '8px', // Скругление углов
        padding: '10px', // Увеличение размера кнопки
      }}
    >
      <FcGoogle size={24} />
    </IconButton>
  );
};

export default GoogleAuthButton;
