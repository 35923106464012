import React, { useEffect } from 'react';
import * as VKID from '@vkid/sdk';

const VKOneTabAuth = () => {
  useEffect(() => {
    VKID.Config.init({
      app: 52094481,
      redirectUrl: 'https://charnic.ru/login',
      state: 'state',
      codeVerifier: 'codeVerifier',
      mode: VKID.ConfigAuthMode.Redirect,
      scheme: VKID.Scheme.DARK,

    });

    const oneTap = new VKID.OneTap();

    const container = document.getElementById('VkIdSdkOneTap');

    if (container) {
      oneTap.render({ container }).on(VKID.WidgetEvents.ERROR, console.error);
    }

    return () => {
      // Удаляем содержимое контейнера при размонтировании компонента
      const container = document.getElementById('VkIdSdkOneTap');
      if (container) {
        container.innerHTML = '';
      }
    };
  }, []);

  return (
    <div id="VkIdSdkOneTap" sx={{
              width: 'min-content'}}></div>
  );
};

export default VKOneTabAuth;
