import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { getFirestore, getDocs, collection } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCcy7jYlKi7KvwkFWjWg-2dqlJfoNg8vPY",
  authDomain: "charnic-2f915.firebaseapp.com",
  projectId: "charnic-2f915",
  storageBucket: "charnic-2f915.appspot.com",
  messagingSenderId: "1001151763374",
  appId: "1:1001151763374:web:8532b67995feafea7a6e3f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Экспортируем необходимые объекты
export const auth = getAuth(app);
export { 
  initializeApp,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  db,
  getDocs,
  collection
};


// Добавляем функцию createUser
export const createUser = async (email, password) => {
   try {
     const userCredential = await createUserWithEmailAndPassword(auth, email, password);
     const user = userCredential.user;
     await sendEmailVerification(user);
     return userCredential;
   } catch (error) {
     throw error;
   }
 };